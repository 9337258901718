import * as React from 'react';
import SEO from '../components/seo';
import { FlexContent } from '../components/grid';
const PageNotFound = ({ pageContext }) => {
    const { lang } = pageContext;
    return (<FlexContent>
      <SEO title="404: Not Found" lang={lang}/>
      <h1>Not Found</h1>
    </FlexContent>);
};
export default PageNotFound;
